/* Contacto */
#contacto .red-line-block .red-line {
    width: 28px;
    height: 149px;
    background-color: #FF5A5D;
}

#contacto .red-line-block .text-hablemos {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
}

#contacto .contacto-user .contacto-title {
    font-size: 42px;
    font-weight: 300;
    line-height: 50px;
    color: #25252D;
}

#contacto .contacto-user p:nth-child(2),
#contacto .contacto-user p:nth-child(4) {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #939393;
}

#contacto .contacto-user p:nth-child(3) {
    font-size: 30px;
    font-weight: 400;
    line-height: 41px;
    color: #000000;
}

#contacto .lets-talk {
    background-color: #FF5A5D;
    padding-top: 14em;
}

#contacto .lets-talk p {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    color: #FFFFFF;
}

#contacto form ::placeholder,
select {
    color: #CBCBCB !important;
}

#contacto form .form-select {
    background-image: url(../../../assets/images/chevron.svg) !important;
}

#contacto form textarea {
    resize: none;
}

#contacto .btn-send {
    width: 90px;
    height: 35px;
    color: #F2F2F4;
    background-color: #FF5A5D;
    border: none;
}

/* animaciones */

[data-animation="down"] {
    opacity: 0;
    transform: translateY(-100%);
    transition: .6s ease-in .3s;

    --opacity: 1;
    --transform: translateY(0);
}

.unset {
    opacity: var(--opacity);
    transform: var(--transform);
}
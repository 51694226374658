#proyectos {
    background-color: #ffff;
}

#proyectos .button-container {
    z-index: 1;
    bottom: 100px;
    left: 164px;
    right: 0;
}

#proyectos .button-container button img {
    filter: brightness(0) saturate(100%) invert(74%) sepia(10%) saturate(509%) hue-rotate(106deg) brightness(92%) contrast(89%);
}

/* 

#proyectos #carouselProyectos .prev-button {
    background: 0 0;
    color: beige;
    border: 0;
    transition: opacity .15s ease;
}

#proyectos #carouselProyectos .next-button {
    background: 0 0;
    color: beige;
    border: 0;
    transition: opacity .15s ease;
}

#proyectos #carouselProyectos .next-button .carousel-control-next-icon,
#proyectos #carouselProyectos .prev-button .carousel-control-prev-icon {
    filter: brightness(0) saturate(100%) invert(74%) sepia(10%) saturate(509%) hue-rotate(106deg) brightness(92%) contrast(89%);
}


#proyectos #carouselProyectos .next-button .carousel-control-next-icon:hover,
#proyectos #carouselProyectos .prev-button .carousel-control-prev-icon:hover {
    filter: brightness(0) saturate(100%) invert(41%) sepia(56%) saturate(2323%) hue-rotate(329deg) brightness(115%) contrast(101%) !important;
}

#proyectos .carousel-inner {
    overflow: visible;
} */

#proyectos .nuestro-trabajo .title {
    font-size: 42px;
    font-weight: 300;
    line-height: 50px;
    color: #777777;
}

#proyectos .nuestro-trabajo .title span {
    font-size: 42px;
    font-weight: 400;
    line-height: 50px;
    color: #FF5A5D;
}

#proyectos .slick-slider .slick-prev, #proyectos .slick-slider .slick-next{
    left: unset;
    right: unset;
    top: 200px;
}

#proyectos .slick-slider .slick-next{
    right: 20px;
}

#proyectos .slick-slider .slick-prev{
    right: 60px;
}

#proyectos .nuestro-trabajo .subtitle {
    font-size: 22px;
    font-weight: 400;
    line-height: 35px;
    color: #000000;
}

#proyectos .nuestro-trabajo .date-company {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #767676;
}

#proyectos .descripcion-proyecto {
    height: 26rem;
    padding-left: 11rem;
}

#proyectos .descripcion-proyecto .descripcion {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #767676;
}

#proyectos .descripcion-proyecto .ver-proyecto {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #E82E31;
    text-decoration: none;
}

#proyectos .cellphone-1 {
    background-image: url(../../../assets/images/cellphone-2.png), url(../../../assets/images/cellphone-1.png);
    background-position: 80px 70px, 0px 5px;
    background-repeat: no-repeat, no-repeat;
    background-size: 211px, 211px;
    height: 445px;
    width: 301px;
    top: 68%;
    left: 5%;
    transform: translateX(-50%) translateY(-50%);
}

#proyectos .projects-items {
    margin: 0 0 0 25%;
}

#proyectos .projects-items::-webkit-scrollbar {
    height: 5px;
}

#proyectos .projects-items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
}

#proyectos .projects-items::-webkit-scrollbar-thumb {
    background: #746e6e;
    border-radius: 10px;
}

#proyectos .projects-items::-webkit-scrollbar-thumb:hover {
    background: #767676;
}

@media screen and (min-width: 576px) {
    #proyectos .button-container {
        z-index: 1;
        bottom: 135px;
        left: 280px;
        right: 0;
    }

    #proyectos .descripcion-proyecto .descripcion {
        text-align: justify;
    }

    #proyectos .descripcion-proyecto {
        padding-left: 17rem;
    }

    #proyectos .cellphone-1 {
        left: 24%;
    }
}

@media screen and (min-width: 768px) {
    #proyectos .button-container {
        left: 368px;
    }

    #proyectos .descripcion-proyecto {
        padding-left: 21rem;
    }

    #proyectos .cellphone-1 {
        left: 27%;
    }
}

@media screen and (min-width: 992px) {

    #proyectos .nuestro-trabajo .title {
        font-size: 34px;
    }
    
    #proyectos .nuestro-trabajo .title span {
        font-size: 35px;
    }

    #proyectos .button-container {
        bottom: 187px;
        right: 50px;
        top: 0px;
    }

    #proyectos .row {
        height: 35em;
    }

    #proyectos .descripcion-proyecto {
        height: auto;
        padding-left: 0;
        padding-top: 17rem !important;
    }

    #proyectos .nuestro-trabajo {
        padding-top: 7rem !important;
    }

    #proyectos .nuestro-trabajo .nuestro-trabajo-title,
    #proyectos .nuestro-trabajo .nuestro-trabajo-title span {
        font-size: 41px;
    }

    #proyectos .descripcion-proyecto .descripcion {
        text-align: start;
    }

    #proyectos .cellphone-1 {
        top: 45%;
        left: 9%;
        background-size: 283px, 277px;
        height: 540px;
        width: 427px;
        background-position: 146px 85px, 4px 11px;
        transform: translate(-43%, -52%);
    }
}

@media screen and (min-width: 1200px) {
    #proyectos .cellphone-1 {
        left: 13%;
        transform: translate(-46%, -52%);
    }
}

@media screen and (min-width: 1400px) {
    #proyectos .button-container {
        bottom: 337px;
    }

    #proyectos .row {
        height: 45em;
    }

    #proyectos .cellphone-1 {
        top: 39%;
        left: 12%;
        background-size: 317px, 317px;
        height: 621px;
        width: 481px;
        background-position: 162px 110px, 4px 11px;
        transform: translate(-53%, -50%);
    }
}

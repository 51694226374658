#footer {
    background-color: #171E26;
}

#footer .img-logo-footer {
    width: 71px;
    height: 38px;
}

#footer ul .hablemos-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #F2F2F4;
}

#footer ul .partnerships-text {
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    color: #C4C4C4;
}

#footer ul .appian {
    width: 47px;
    height: 21px;
}

#footer ul .aira {
    width: 38px;
    height: 15px;
}

@media screen and (min-width: 991px) {
    #footer .img-logo-footer {
        width: 111px;
        height: 59px;
    }

    #footer ul .partnerships-text {
        font-size: 14px;
    }

    #footer ul .appian {
        width: 62px;
        height: 28px;
    }

    #footer ul .aira {
        width: 50px;
        height: 20px;
    }
}
.section_bajada_nosotros{
  padding-block: 7rem;
  background-color: #FFF;
}

.section_bajada_nosotros span{
  color: #777;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.125rem;
}

.section_bajada_nosotros h2{
  color: #777;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 3.125rem;
  max-width: 29ch;
}

.section_bajada_nosotros h2 strong{
  color: #FF5A5D;
}

.section_bajada_nosotros p{
  color: #25252D;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  max-width: 65ch;
}
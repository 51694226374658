.section_adn_nosotros {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.section_adn_nosotros::before {
  content: "";
  width: 1.75rem;
  height: 8.25rem;
  background-color: #FF5A5D;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.section_adn_nosotros-header {
  margin-inline: auto;
  margin-top: 9rem;
}

.section_adn_nosotros-header span {
  display: block !important;
  text-align: center !important;
  color: #777 !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.section_adn_nosotros-header h3 {
  display: block !important;
  text-align: center !important;
  color: #000 !important;
  font-size: 1.875rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 1.875rem !important;
  margin-bottom: 1.25rem !important;
}

.section_adn_nosotros-header small {
  display: block !important;
  color: #777 !important;
  text-align: center !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.section_adn_nosotros-timeline {
  margin-inline: auto;
}

.section_adn_nosotros-timeline_item {
  padding-block: 1.75rem;
  display: flex;
}

.section_adn_nosotros-timeline_item_number span {
  color: #FFF;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.5rem;
  background-color: #FF5A5D;
  margin-right: 1rem;
  padding: 0.3rem;
  border-radius: 50%;
}

.section_adn_nosotros-timeline_item_text strong {
  color: #000 !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  letter-spacing: -0.045rem !important;
}

.section_adn_nosotros-timeline_item_text p {
  color: #25252D !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.375rem !important;
  max-width: 38ch;
}


/* hola */

.section_adn_nosotros-timeline {
  margin: 20px auto;
  padding: 20px;
  counter-reset: counter;
}

/* timeline_card container */
.section_adn_nosotros-timeline_card {
  position: relative;
  max-width: 700px;
}

/* setting padding based on even or odd */
.section_adn_nosotros-timeline_card:nth-child(odd) {
  padding: 30px 0 30px 30px;
}

.section_adn_nosotros-timeline_card:nth-child(even) {
  padding: 30px 30px 30px 0;
}

/* Global ::before */
.section_adn_nosotros-timeline_card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid #FF5A5D;
}

/* Setting the border of top, bottom, left */
.section_adn_nosotros-timeline_card:nth-child(odd)::before {
  left: 0px;
  top: -4.5px;
  bottom: -4.5px;
  border-width: 5px 0 5px 5px;
  border-radius: 50px 0 0 50px;
}

/* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
@media only screen and (max-width: 400px) {
  .section_adn_nosotros-timeline_card:nth-child(odd)::before {
    top: -5px;
    bottom: -5px;
  }
}

/* Setting the border of top, bottom, right */
.section_adn_nosotros-timeline_card:nth-child(even)::before {
  right: 0;
  top: 0;
  bottom: -1px;
  border-width: 5px 5px 5px 0;
  border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first timeline_card */
.section_adn_nosotros-timeline_card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}

/* Removing the border if it is the last timeline_card  and it's odd */
.section_adn_nosotros-timeline_card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

/* Removing the border if it is the last timeline_card  and it's even */
.section_adn_nosotros-timeline_card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

/* Information about the timeline */
.section_adn_nosotros-info {
  color: #25252D !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.375rem !important;
}

/* Title of the timeline_card */
.section_adn_nosotros-title {
  display: block;
  text-align: left;
  color: #000 !important;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem !important;
  margin-bottom: 1.25rem !important;
  position: relative;
}

/* Timeline dot  */
.section_adn_nosotros-title::before {
  counter-increment: counter;
  content: counter(counter);
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 999px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  background-color: #FF5A5D;
}

/* text right if the timeline_card is even  */
.section_adn_nosotros-timeline_card:nth-child(even)>.section_adn_nosotros-info>.section_adn_nosotros-title {
  text-align: right;
}

/* setting dot to the left if the timeline_card is odd */
.section_adn_nosotros-timeline_card:nth-child(odd)>.section_adn_nosotros-info>.section_adn_nosotros-title::before {
  left: -42px;
}

/* setting dot to the right if the timeline_card is odd */
.section_adn_nosotros-timeline_card:nth-child(even)>.section_adn_nosotros-info>.section_adn_nosotros-title::before {
  right: -40px;
}
#experiencia-digital .vl {
    height: 130px;
    width: 28px;
    background-color: #FF5A5D;
}

#experiencia-digital .experiencia-digital-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
    color: #777777;
    text-transform: uppercase;
}

#experiencia-digital .experiencia-digital-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    color: #000000;
}

#experiencia-digital ul.nav {
    display: -webkit-box !important;
}

#experiencia-digital ul li {
    width: max-content;
}

#experiencia-digital .nav-underline .nav-link {
    color: #000000;
    font-weight: 700;
}

#experiencia-digital .nav-underline .nav-link.active {
    font-weight: 700;
    color: #FF5A5D;
    border-bottom-color: #FF5A5D;
}

#experiencia-digital .nav-underline .nav-link:hover {
    color: #000000;
    border-bottom-color: #FF5A5D;
}

#experiencia-digital .tab-pane p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #777777;
}

#experiencia-digital .slick-slider .slick-list .card .responsive-img {
    background-color: #FF5A5D;
    height: 71px;
    width: 71px;
    border-radius: 55%;
}

#experiencia-digital .slick-slider .slick-list .card .card-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
}

#experiencia-digital .slick-slider .slick-list .card .card-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

#experiencia-digital .slick-slider .slick-dots {
    display: flex;
    position: initial;
    margin: 0 0 3em;
}

#experiencia-digital .slick-slider .slick-dots li button::before {
    font-size: 11px;
    opacity: 1;
    color: #777777;
}

#experiencia-digital .slick-slider .slick-dots li.slick-active button::before {
    opacity: 1;
    color: #FF5A5D;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

:root {
    --bs-form-select-bg: url(./assets/images/chevron.svg);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

h3 {
    font-size: 2rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
}

h4 {
    color: #FFF !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.slick-prev,
.slick-next{ height: 40px !important;}

.slick-prev::before {
    content: '' !important;
    border-right: #777777 2px solid;
    content: '';
    display: block;
    height: 23px;
    margin-top: -16px;
    position: absolute;
    transform: rotate(225deg);
    right: 10px;
    top: 50%;
    width: 0;
    opacity: 1;
}

.slick-prev:hover::before {
    border-color: #E82E31;
}

.slick-prev::after {
    border-right: #777777 2px solid;
    content: '';
    display: block;
    height: 23px;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 0;
    margin-top: -1px;
    transform: rotate(135deg);
    opacity: 1;
}

.slick-prev:hover::after {
    border-color: #E82E31;
}

.slick-next::before {
    content: '' !important;
    border-right: #777777 2px solid;
    content: '';
    display: block;
    height: 23px;
    margin-top: -16px;
    position: absolute;
    transform: rotate(135deg);
    right: 10px;
    top: 50%;
    width: 0;
    opacity: 1;
}

.slick-next:hover::before {
    border-color: #E82E31;
}

.slick-next::after {
    border-right: #777777 2px solid;
    content: '';
    display: block;
    height: 23px;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 0;
    margin-top: -1px;
    transform: rotate(45deg);
    opacity: 1;
}

.slick-next:hover::after {
    border-color: #E82E31;
}
#experiencias {
    background-color: #171E26;
}

#experiencias .beneficios-content .beneficios-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
    color: #777777;
    text-transform: uppercase;
}

#experiencias .beneficios-content h1 {
    font-size: 42px;
    font-weight: 300;
    line-height: 50px;
    color: #777777;
}

#experiencias .beneficios-content h1 span {
    font-size: 42px;
    font-weight: 400;
    line-height: 50px;
    color: #FF5A5D;
}

#experiencias .beneficios-content p {
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    color: #939393;
}

#experiencias .beneficios-content a {
    color: #FFFFFF;
    text-decoration: none;
    border: 1px solid #FFFFFF;
    width: fit-content;
}

#experiencias .slick-slider .slick-list .item h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    color: #939393;
}

#experiencias .slick-slider .slick-list .item p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #767676;
}

#experiencias .slick-slider .slick-dots {
    display: flex;
    position: initial;
    margin: 0 0 6em;
}

#experiencias .slick-slider .slick-dots li button::before {
    font-size: 11px;
    opacity: 1;
    color: #777777;
}

#experiencias .slick-slider .slick-dots li.slick-active button::before {
    opacity: 1;
    color: #FF5A5D;
}

@media screen and (min-width: 992px) {

    #experiencias .slick-slider .slick-dots {
        margin: 7px 0;
    }

    #experiencias .button-container {
        top: 82px;
        right: 60px;
    }

    #experiencias {
        padding-block: 4rem;
    }

}
#hero .container {
    height: 546px;
}

#hero {
    background-image: url(../../../assets/images/image2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#hero .media-linkedin {
    bottom: 50px;
    left: 0;
    right: auto;
}

#hero .media-linkedin .media-linkedin--title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #FFFFFF;
    transform: rotate(-90deg);
    text-decoration: none;
}

#hero .caption {
    color: #fff;
    text-align: start;
}

#hero .caption p:nth-child(1) {
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
    text-transform: uppercase;
}

#hero .caption h1 {
    font-size: 38px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
}

#hero .caption p:nth-child(3) {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
}

#hero .laptop-cellphone {
    background-image: url(../../../assets/images/smart-phone.png), url(../../../assets/images/laptop.png);
    background-repeat: no-repeat;
    background-size: 160px 333px, 385px 245px;
    bottom: -158px;
    right: 0;
    height: 366px;
    width: 146px;
    z-index: 1;
    background-position: 9px 46px, 48px -12px;
}

@media screen and (min-width: 992px) {
    #hero .container {
        position: relative;
    }

    #hero .caption {
        margin-bottom: 7em !important;
    }

    #hero .caption p:nth-child(1) {
        font-size: 15px;
        font-weight: 700;
        line-height: 50px;
        text-transform: uppercase;
    }

    #hero .caption h1 {
        font-size: 40px;
        font-weight: 700;
        line-height: 40px;
        text-transform: uppercase;
    }

    #hero .caption p:nth-child(3) {
        font-size: 17px;
        font-weight: 400;
        line-height: 34px;
    }

    #hero .laptop-cellphone {
        background-size: 159px 315px, 405px 263px;
        bottom: -118px;
        height: 362px;
        right: -16px;
        width: 461px;
        z-index: 1;
        background-position: 58px 58px, 61px 0px;
    }
}

@media screen and (min-width: 1200px) {
    #hero .caption {
        right: 15%;
        left: 5%;
        color: #fff;
        text-align: center;
        top: 40%;
    }

    #hero .laptop-cellphone {
        background-size: 159px 315px, 405px 263px;
        bottom: -118px;
        height: 362px;
        right: 0;
        width: 461px;
        z-index: 1;
        background-position: 58px 58px, 61px 0px;
    }
}

@media screen and (min-width: 1400px) {

    #hero .caption p:nth-child(1) {
        font-size: 15px;
        font-weight: 700;
        line-height: 50px;
        text-transform: uppercase;
    }

    #hero .caption h1 {
        font-size: 52px;
        font-weight: 700;
        line-height: 55px;
        text-transform: uppercase;
    }

    #hero .caption p:nth-child(3) {
        font-size: 20px;
        font-weight: 400;
        line-height: 34px;
    }

    #hero .laptop-cellphone {
        background-size: 210px 405px, 543px 340px;
        bottom: -105px;
        right: -40px;
        height: 484px;
        width: 590px;
        z-index: 1;
        background-position: 44px 80px, 48px 0px;
    }
}
h3 {
  color: #FF5A5D !important;
  display: block;
  text-align: center;
  margin-bottom: 5rem !important;
}

h3 span{
  color: #777;
  display: block;
}

.section_experiencia{
  background-color: #171E26;
  padding-block: 4.5rem;
  position: relative;
}

.section_experiencia_cards{
  display: inline-block;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.section_experiencia_card{
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

.section_experiencia_card-header{
  border-radius: 0.75rem;
  overflow: hidden;
  position: relative;
}

.section_experiencia_card-header::after{
  content: "";
  display: block;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  inset: 0;
  background-color: #FA24246E;
  transition: visibility 100ms ease-in-out, opacity 100ms ease-in-out;
}

.section_experiencia_card-header:hover::after{
  visibility: visible;
  opacity: 1;
}

.section_experiencia_card-header_hover{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  inset: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 100ms ease-in-out, opacity 100ms ease-in-out;
}

.section_experiencia_card-header:hover .section_experiencia_card-header_hover{
  visibility: visible;
  opacity: 1;
  z-index: 100;
}

.section_experiencia_card-header_hover a{
  color: #FFF;
  position: relative;
  transition: color 100ms ease-in-out;
}

.section_experiencia_card-header_hover a::after{
  content: "";
  display: block;
  position: absolute;
  border: 2px solid #FFF;
  padding: 1.25rem;
  border-radius: 50%;
  top: 0;
  left: 0;
  translate: -30% -15%;
  transition: border-color 100ms ease-in-out;
}

.section_experiencia_card-header_hover a:hover{
  color: #171E26;
}

.section_experiencia_card-header_hover a:hover::after{
  border-color: #171E26;
}

.section_experiencia_card-body small {
  display: block;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.875rem;
  color: #939393;
  text-align: center;
}
.section_experiencia_card-body strong {
  display: block;
  color: #767676;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

.section_experiencia .slick-dots {
  bottom: 0px;
  display: flex;
  position: initial;
  margin-top: 6rem;
}

.section_experiencia .slick-dots li button::before {
  font-size: 11px;
  opacity: 1;
  color: #777777;
}

.section_experiencia .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #FF5A5D;
}
/* Servicios */
#servicio {
    padding-block: 4rem;
}

#servicio .nuestro-servicio span {
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
    color: #777777;
}

#servicio .nuestro-servicio h2 {
    font-size: 42px;
    font-weight: 300;
    line-height: 50px;
    color: #777777;
}

#servicio .nuestro-servicio h2 span {
    font-size: 42px;
    font-weight: 400;
    line-height: 50px;
    color: #FF5A5D;
}

#servicio .nuestro-servicio p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #25252D;
}

#servicio .nuestro-servicio::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 41em;
    background-color: #FF5A5D;
    bottom: -580px;
    right: 35px;
    margin-left: -3px;
    z-index: -1;
    border-bottom: 331px solid #171E26;
}

#servicio .steps::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 50em;
    background-color: #FF5A5D;
    bottom: -232px;
    right: 56%;
    margin-left: -3px;
    z-index: -1;
    border-bottom: 255px solid #171E26;
}

#servicio .steps .box {
    position: relative;
    width: 50%;
}

#servicio .steps .box .badge {
    color: #FFFFFF;
    background-color: #FF5A5D;
    font-weight: 900;
    font-size: 15px;
}

#servicio .steps .box .text-box p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
}

#servicio .steps .box .text-box p span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #FF5A5D;
    padding: 0;
}

#servicio .steps .left-box {
    padding: 10px 70px 10px 0;
    left: 0;
}

#servicio .steps .left-box .badge-service {
    right: 19px;
}

#servicio .steps .right-box {
    padding: 10px 0 10px 50px;
    left: 40%;
}

#servicio .steps .right-box .badge-service {
    left: 0;
}

#servicio .steps .left-box .badge-service,
#servicio .steps .right-box .badge-service {
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF5A5D;
    color: #FFFFFF;
    font-weight: 700;
}

@media screen and (min-width: 992px) {
    #servicio .nuestro-servicio h2 {
        font-size: 37px;
    }

    #servicio .nuestro-servicio h2 span {
        font-size: 37px;
    }

    #servicio .nuestro-servicio::after {
        width: 0;
    }

    #servicio .steps {
        margin-top: 7em !important;
    }
}

@media screen and (min-width: 1200px) {
    #servicio .steps {
        padding-right: 6em !important;
    }

    #servicio .steps .right-box {
        padding: 10px 0 10px 50px;
        left: 60%;
    }

    #servicio .steps .right-box span {
        left: -6px;
        padding: 5px 8px;
    }

    #servicio .steps::after {
        left: 44%;
    }
}

@media screen and (min-width: 1400px) {
    #servicio .steps {
        padding-right: 9em !important;
    }

    #servicio .steps .right-box {
        padding: 10px 0 10px 50px;
        left: 54%;
    }

    #servicio .steps::after {
        left: 39%;
    }
}
#numeros {
    background-color: #ffffff;
}

#numeros h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    color: #000000;
}

#numeros h1 span {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    color: #FF5A5D;
}

#numeros .datos-trabajos h2 {
    font-size: 52px;
    font-weight: 400;
    line-height: 65px;
    color: #222429;
}

#numeros .datos-trabajos p {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    color: #777777;
}

#testimonios {
    background-color: #171E26;
}

#testimonios .main-title {
    padding-top: 4rem;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    color: #FFFFFF;
}

#testimonios .testimonios-clientes {
    padding-top: 10em;
    position: relative;
    overflow: hidden;
}

#testimonios .testimonios-clientes .testimonios-clientes-title {
    font-size: 42px;
    font-weight: 300;
    line-height: 50px;
    color: #777777;
    width: max-content;
}

#testimonios .testimonios-clientes .testimonios-clientes-title span {
    font-size: 42px;
    font-weight: 400;
    line-height: 50px;
    color: #FF5A5D;
}

#testimonios .testimonios-clientes .comillas {
    width: fit-content;
}

#testimonios .testimonios-clientes .testimonios-clientes-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #939393;
}

#testimonios .testimonios-clientes .cliente {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #939393;
}

#testimonios .testimonios-clientes .link-project {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #FF5A5D;
    text-decoration: none;
}

#testimonios .testimonios-clientes .content-picture-person {
    padding-bottom: 6em;
    width: 375px;
}

#testimonios .content-picture-person .iphone {
    position: absolute;
    top: 65%;
    left: 82%;
    transform: translateX(-50%) translateY(-50%);
}

@media screen and (min-width: 992px) {
    #testimonios .slider-carousel .button-container {
        position: absolute;
        top: -103px;
        right: 40px;
    }

    #testimonios .testimonios-clientes .testimonios-clientes-title {
        font-size: 30px;
        margin: 164px 0px;
    }

    #testimonios .testimonios-clientes .testimonios-clientes-title span {
        font-size: 30px;
    }

    #testimonios .testimonios-clientes .content-picture-person {
        width: auto;
    }

    #testimonios .content-picture-person .img-testimonio-person {
        max-width: 100%;
        height: auto;
    }

    #testimonios .content-picture-person .iphone {
        left: 82%;
    }
}

@media screen and (min-width: 1400px) {
    #testimonios .testimonios-clientes .testimonios-clientes-title {
        font-size: 42px;
        margin: 190px 35px;
    }

    #testimonios .testimonios-clientes .testimonios-clientes-title span {
        font-size: 42px;
    }
}
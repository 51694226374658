#ayuda {
    background-color: #171E26;
}

#ayuda .red-line {
    width: 0;
    height: 149px;
    background-color: #FF5A5D;
}

#ayuda .red-line-bottom {
    bottom: 0;
    width: 0;
    height: 60px;
    background-color: #FF5A5D;
}

#ayuda .podemos-ayudar--title {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: -20px;
}

#ayuda .services-consultory-transformation {
    padding-bottom: 20rem;
}

#ayuda .services-operations-risk span,
#ayuda .services-consultory-transformation span {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #C4C4C4;
}

#ayuda .services-operations-risk p,
#ayuda .services-consultory-transformation p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #767676;
}

#ayuda .podemos-ayudar-ayuda {
    background-color: #FF5A5D;
}

#ayuda .podemos-ayudar-ayuda .podemos-ayudar-ayuda-text {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    color: #FFFFFF;
    text-transform: uppercase;
}

#ayuda .cellphone {
    background-image: url(../../../assets/images/cellphone.png);
    position: absolute;
    height: 391px;
    bottom: -54px;
    width: 269px;
    top: 75%;
    right: 0;
    background-size: 383px 396px;
    background-position: 3px -6px;
}

@media screen and (min-width: 992px) {

    #ayuda .services-operations-risk,
    #ayuda .services-consultory-transformation {
        height: 48em;
        padding-top: 8em !important;
    }

    #ayuda .podemos-ayudar-ayuda .podemos-ayudar-ayuda-text {
        height: 24em;
        padding-top: 2em !important;
    }

    #ayuda .cellphone {
        height: 500px;
        width: 472px;
        top: 80%;
        left: 47%;
        transform: translateX(-50%) translateY(-50%);
        background-size: 474px 513px;
        background-position: center;
    }
}

/* Animaciones */
#ayuda .cellphone {
    view-timeline-name: --image;
    view-timeline-axis: block;

    animation-timeline: --image;
    animation-name: show;

    animation-range: entry 20% cover 25%;
    animation-fill-mode: both;
}

@keyframes show {
    from {
        opacity: 0;
        scale: 25%;
    }
    to {
        opacity: 1;
        scale: 100%;
    }
}

[data-animation="down-1"] {
    opacity: 0;
    transform: translateY(-100%);
    transition: .5s ease-in .3s;

    --opacity: 1;
    --transform: translateY(0);
}

.unset1 {
    opacity: var(--opacity);
    transform: var(--transform);
}
#hero .container {
  height: 546px;
}

#hero {
  background-image: url(../../../assets/images/bg-nosotros.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#hero .media-linkedin {
  bottom: 50px;
  left: 0;
  right: auto;
}

#hero .media-linkedin .media-linkedin--title {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #FFFFFF;
  transform: rotate(-90deg);
  text-decoration: none;
}

#hero .caption {
  color: #fff;
  text-align: start;
}

#hero .caption p:nth-child(1) {
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
}

#hero .caption h1 {
  font-size: 38px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}

#hero .caption p:nth-child(3) {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
}

#hero .pills {
  background-image: url(../../../assets/images/Pill2.png), url(../../../assets/images/Pill1.png);
  background-repeat: no-repeat;
  bottom: -300px;
  right: 0;
  height: 550px;
  width: 175px;
  z-index: 1;
  background-position: -20px 0px, 0;
  overflow: visible;
}

.bajadaHeroNosotros{
  max-width: 56ch;
}

@media screen and (min-width: 992px) {
  #hero .container {
      position: relative;
  }

  #hero .caption {
      margin-bottom: 7em !important;
  }

  #hero .caption p:nth-child(1) {
      font-size: 15px;
      font-weight: 700;
      line-height: 50px;
      text-transform: uppercase;
  }

  #hero .caption h1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      text-transform: uppercase;
  }

  #hero .caption p:nth-child(3) {
      font-size: 17px;
      font-weight: 400;
      line-height: 34px;
  }

  #hero .pills {
      bottom: -118px;
      height: 362px;
      right: -16px;
      width: 350px;
      z-index: 1;
      background-position: 110px 58px, 61px 0px;
  }
}

@media screen and (min-width: 1200px) {
  #hero .caption {
      right: 15%;
      left: 5%;
      color: #fff;
      text-align: center;
      top: 40%;
  }

  #hero .pills {
      bottom: -118px;
      height: 362px;
      right: 0;
      width: 461px;
      z-index: 1;
      background-position: 58px 58px, 61px 0px;
  }
}

@media screen and (min-width: 1400px) {

  #hero .caption p:nth-child(1) {
      font-size: 15px;
      font-weight: 700;
      line-height: 50px;
      text-transform: uppercase;
  }

  #hero .caption h1 {
      font-size: 52px;
      font-weight: 700;
      line-height: 55px;
      text-transform: uppercase;
  }

  #hero .caption p:nth-child(3) {
      font-size: 20px;
      font-weight: 400;
      line-height: 34px;
  }

  #hero .pills {
      bottom: -220px;
      right: 0px;
      height: 550px;
      width: 300px;
      z-index: 1;
      background-position: 100px 150px, 0;
  }
}
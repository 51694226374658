.section_partners{
  background-color: #171E26;
  padding-block: 5.5rem 9rem ;
  display: grid;
  place-content: center;
}
.section_partners_IMGS{
  display: flex;
  align-items: center;
  gap: 8rem;
  flex-wrap: wrap;
}

h4{
  margin-bottom: 4rem;
  text-align: center;
}

@media screen and (width < 768px) {
  .section_partners_IMGS{
    flex-direction: column;
    padding-top: 3rem
  }
}
#comoTrabajamos {
    background-color: #171E26;
}

#comoTrabajamos .comoTrabajamos-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    color: #ffffff;
}

#comoTrabajamos .content {
    padding-bottom: 20rem;
}

#comoTrabajamos .timeline {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

#comoTrabajamos .timeline li {
    height: 100px;
    border-color: #777777;
    width: 51%;
}

#comoTrabajamos .timeline li:nth-child(1) {
    border-style: solid;
    border-width: 0 0 4px;
    box-shadow: -15px 0 15px -12px rgba(0, 0, 0, .5);
    margin: 0 0 -4px 0;
    float: left;
}

#comoTrabajamos .timeline li:nth-child(2) {
    border-style: solid;
    border-width: 4px 4px 4px 0;
    border-radius: 0 80px 80px 0;
    box-shadow: 13px 0 15px -12px rgba(0, 0, 0, .3);
    margin: 0 1rem -4px 0;
    float: right;
}

#comoTrabajamos .timeline li:nth-child(3) {
    border-style: solid;
    border-width: 4px 0 4px 4px;
    border-radius: 80px 0 0 80px;
    box-shadow: -15px 0 15px -12px rgba(0, 0, 0, .5);
    margin: 0 0 -4px 0;
    float: left;
}

#comoTrabajamos .timeline li:nth-child(4) {
    border-style: solid;
    border-left-color: #171E26;
    border-width: 4px 0 0;
    box-shadow: 13px 0 15px -12px rgba(0, 0, 0, .3);
    float: right;
}

#comoTrabajamos .timeline li:nth-child(5) {
    border: 0;
}

#comoTrabajamos .timeline li .box-info {
    position: absolute;
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

#comoTrabajamos .timeline li:nth-child(1) .box-info {
    top: 20px;
    left: 8%;
}

#comoTrabajamos .timeline li:nth-child(2) .box-info {
    top: 115px;
    right: 20%;
}

#comoTrabajamos .timeline li:nth-child(3) .box-info {
    top: 212px;
    right: 67%;
}

#comoTrabajamos .timeline li:nth-child(4) .box-info {
    top: 212px;
    right: 4%;
}

#comoTrabajamos .timeline li .box-info .text-number {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF5A5D;

    font-size: 42px;
    font-weight: 800;
    color: #171E26;
}

#comoTrabajamos .timeline li .box-info .text-desc {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #939393;
    margin: 0;
}

#comoTrabajamos .timeline li .box-info .open-tooltip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #C4C4C4;
    text-align: center;
    line-height: 22px;
    font-weight: bold;
    font-size: 37px;
    color: #171E26;
    position: relative;
    display: inline-block;
}

#comoTrabajamos .timeline li .box-info .open-tooltip:hover {
    cursor: default;
    background-color: #FF5A5D;
}

#comoTrabajamos .timeline li .box-info .open-tooltip .tooltiptext {
    visibility: hidden;
    width: 14rem;
    background-color: #ffd9d9;
    text-align: center;
    border-radius: 6px;
    padding: 7px 20px;
    position: absolute;
    z-index: 1;
    text-align: left;
}

#comoTrabajamos .timeline li:nth-child(1) .box-info .open-tooltip .tooltiptext {
    top: 0;
    left: 135%;
}

#comoTrabajamos .timeline li:nth-child(2) .box-info .open-tooltip .tooltiptext {
    top: 40px;
    right: 16%;
}

#comoTrabajamos .timeline li:nth-child(3) .box-info .open-tooltip .tooltiptext {
    bottom: 0;
    left: 124%;
}

#comoTrabajamos .timeline li:nth-child(4) .box-info .open-tooltip .tooltiptext {
    bottom: 0;
    right: 135%;
}

#comoTrabajamos .timeline li .box-info .open-tooltip .tooltiptext h1 {
    font-size: 16px;
    font-weight: 700;
}

#comoTrabajamos .timeline li .box-info .open-tooltip .tooltiptext p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

#comoTrabajamos .timeline li .box-info .open-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    top: 92%;
    right: 100%;
    border-color: transparent #FFD9D9F2 transparent transparent;
}

#comoTrabajamos .timeline li:nth-child(1) .box-info .open-tooltip .tooltiptext::after {
    top: 16px;
    right: 100%;
    border-color: transparent #FFD9D9F2 transparent transparent;
}

#comoTrabajamos .timeline li:nth-child(2) .box-info .open-tooltip .tooltiptext::after {
    top: -5px;
    left: 93%;
    border-color: transparent transparent #FFD9D9F2 transparent;
}

#comoTrabajamos .timeline li:nth-child(4) .box-info .open-tooltip .tooltiptext::after {
    bottom: 10px;
    left: 100%;
    border-color: transparent transparent transparent #FFD9D9F2;
}

#comoTrabajamos .timeline li .box-info .open-tooltip:hover .tooltiptext {
    visibility: visible;
}

@media screen and (min-width: 992px) {
    #comoTrabajamos .timeline li:nth-child(2) {
        margin: 0 5rem -4px 0;
    }

    #comoTrabajamos .timeline li:nth-child(1) .box-info {
        top: 20px;
        left: 5%;
    }

    #comoTrabajamos .timeline li:nth-child(2) .box-info {
        top: 20px;
        left: 32%;
    }

    #comoTrabajamos .timeline li:nth-child(3) .box-info {
        right: 30%;
        top: 21px;
    }

    #comoTrabajamos .timeline li:nth-child(4) .box-info {
        top: 114px;
        right: 17%;
    }

    #comoTrabajamos .timeline li:nth-child(5) .box-info {
        top: 116px;
        right: 45%;
    }

    #comoTrabajamos .timeline li:nth-child(3) {
        margin: 0 0 -4px 7rem;
    }

    #comoTrabajamos .timeline li:nth-child(1) .box-info .open-tooltip .tooltiptext {
        bottom: 0;
        top: auto;
    }

    #comoTrabajamos .timeline li:nth-child(2) .box-info .open-tooltip .tooltiptext {
        top: auto;
        bottom: 0;
        left: 133%;
    }

    #comoTrabajamos .timeline li:nth-child(4) .box-info .open-tooltip .tooltiptext {
        bottom: auto;
        right: 133%;
    }

    #comoTrabajamos .timeline li:nth-child(4) .box-info .open-tooltip .tooltiptext {
        right: 134%;
    }

    #comoTrabajamos .timeline li:nth-child(5) .box-info .open-tooltip .tooltiptext {
        right: 134%;
    }

    #comoTrabajamos .timeline li:nth-child(1) .box-info .open-tooltip .tooltiptext::after {
        top: auto;
        bottom: 10px;
    }

    #comoTrabajamos .timeline li:nth-child(2) .box-info .open-tooltip .tooltiptext::after {
        bottom: 10px;
        top: auto;
        left: auto;
        right: 100%;
        border-color: transparent #FFD9D9F2 transparent transparent;
    }

    #comoTrabajamos .timeline li:nth-child(4) .box-info .open-tooltip .tooltiptext::after {
        left: 100%;
        top: 18px;
        bottom: auto;
    }

    #comoTrabajamos .timeline li:nth-child(5) .box-info .open-tooltip .tooltiptext::after {
        left: 100%;
        top: 16px;
        bottom: auto;
        border-color: transparent transparent transparent #FFD9D9F2;
    }
}

@media screen and (min-width: 1200px) {
    #comoTrabajamos .timeline li:nth-child(2) {
        margin: 0 9rem -4px 0;
    }
    
    #comoTrabajamos .timeline li:nth-child(3) {
        margin: 0 0 -4px 17rem;
    }
}
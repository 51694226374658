#heroInicio {
    height: 100vh;
}

#heroInicio .slider-carousel .slick-prev {
    left: 20px;
    z-index: 1;
    top: 90%;
}

#heroInicio .slider-carousel .slick-next {
    left: 60px;
    z-index: 1;
    top: 90%;
}

#heroInicio .slider-carousel .slick-dots {
    top: 89%;
}

#heroInicio .slider-carousel .slick-dots li button::before {
    font-size: 11px;
    opacity: 1;
    color: #E2E2E2;
}

#heroInicio .slider-carousel .slick-dots li.slick-active button::before {
    opacity: 1;
    color: #FF5A5D;
}

#heroInicio .slider-carousel .caption-carousel h1 {
    font-size: 38px;
    font-weight: 700;
    line-height: 40px;
    color: #FFFFFF;
    text-transform: uppercase;
    width: 20rem;
}

#heroInicio .slider-carousel .caption-carousel p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #FFFFFF;
    width: 23rem;
}

#heroInicio .slider-carousel .media-linkedin {
    bottom: 63px;
    z-index: 1;
    right: 0;
}

#heroInicio .slider-carousel .media-linkedin .media-linkedin--title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #FFFFFF;
    transform: rotate(-90deg);
    text-decoration: none;
}

@media screen and (min-width: 576px) {
    #heroInicio .slider-carousel .caption-carousel p {
        width: 35rem;
    }
}

@media screen and (min-width: 992px) {
    #heroInicio .slider-carousel .caption-carousel h1 {
        width: 30rem;
    }

    #heroInicio .slider-carousel .caption-carousel p {
        width: 54rem;
    }

    #heroInicio .slider-carousel .slick-dots {
        width: auto;
        left: 100px;
    }

    #heroInicio .slider-carousel .media-linkedin {
        bottom: 200px;
        left: 10px;
        right: auto;
    }

    #heroInicio .slider-carousel .caption-carousel h1 {
        font-size: 72px;
        font-weight: 700;
        line-height: 76px;
    }

    #heroInicio .slider-carousel .caption-carousel p {
        font-size: 20px;
        font-weight: 400;
        line-height: 34px;
    }
}
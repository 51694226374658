.navbar {
    background-color: #051A3F;
}

.navbar .navbar-logo {
    height: 39px;
    width: 73px;
}

.navbar .navbar-toggler {
    outline: none;
}

.navbar .navbar-toggler .navbar-toggler-icon {
    background-image: url('../../../assets/images/burger.png');
    filter: brightness(0) saturate(100%) invert(52%) sepia(59%) saturate(4543%) hue-rotate(326deg) brightness(110%) contrast(115%);
}

.navbar .navbar-collapse ul li a, .navbar .navbar-collapse ul li button {
    color: #fff;
}

.navbar .navbar-collapse ul li a.active, .navbar .navbar-collapse ul li button.active {
    color: #FF5A5D !important;
}

.navbar .hablemos-button {
    border: 1px solid #FFFFFF;
    text-decoration: none;
    color: #FFFFFF;
}

@media screen and (min-width: 992px) {
    .navbar {
        background-color: transparent;
    }

    .navbar .navbar-logo {
        height: 69px;
        width: 130px;
    }

    .nav-bg-dark {
        background-color: #051A3F !important;
        height: 60px;
    }

    .nav-bg-dark .navbar-logo {
        height: 47px;
        width: 89px;
    }

    .nav-bg-dark .hablemos-button {
        height: 30px;
        width: 121px;
    }

    .nav-bg-dark .hablemos-button span {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        color: #F2F2F4;        
    }
}

.dropdown-nav .dropdown-menu{
    background-color: #09213C !important;
    padding: 2rem 1.75rem;
    display: none;
}

.dropdown-nav .dropdown-menu.show{
    display: flex !important;
}

.dropdown-nav .dropdown-menu .dropdown-menu_child{
    border-right: 1px solid #FFFFFF38;
    padding-right: 2rem;
    min-width: max-content;
}

.dropdown-nav .dropdown-menu .dropdown-menu_child:nth-child(n+1){
    padding-left: 2rem;
}

.dropdown-nav .dropdown-menu .dropdown-menu_child:last-child{
    border-right: none;
    padding-right: 0;
}

.dropdown-nav .dropdown-menu .dropdown-menu_header{
    color: #fff !important;
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

.dropdown-nav .dropdown-menu .dropdown-menu_header svg{
    width: 25px;
    height: 25px;
}
.dropdown-nav .dropdown-menu .dropdown-menu_header strong{
    display: block;
    color: #FFF;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.8125rem;
}

.dropdown-nav .dropdown-menu ul{
    padding: 0;
}

.dropdown-nav .dropdown-menu ul li a{
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-decoration: none;
}

.dropdown-nav .dropdown-menu ul li a:hover{
    color: #FF5A5D;
}